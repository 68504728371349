export enum TrainingStatus {
  Untrained = 'untrained',
  Scheduled = 'scheduled',
  Trained = 'trained',
  Error = 'error'
}

export enum SavingStatus {
  Unsaved = 'unsaved',
  Saved = 'saved',
  Changed = 'changed'
}

// export interface KnowledgeStatus {
//   kb: TrainingStatus;
//   wit: TrainingStatus;
// }