import { REGEX_ZH, REGEX_PUNCTUATION, REGEX_EN } from "../misc";

export const containZH = (text: string): boolean => {
	return text.match(REGEX_ZH) ? true : false;
};

export const containEN = (text: string): boolean => {
	return text.match(REGEX_EN) ? true : false;
};

export const isZH = (text: string): boolean => {
	if (!containZH(text)) return false;
	if (containEN(text)) return false;
	return true;
};

export const isPunctuation = (text: string): boolean => {
	return text.match(REGEX_PUNCTUATION) ? true : false;
};
