import { Response, ResponseType } from "../messenger/response";
import { TrainingStatus } from "./status";

export * from "./status";
export * from "./semantic";
export * from "./builtin";

export interface KnowledgeParam {
  clientId: string;
  knowledgeId?: string;
  locales?: { [key: string]: LocaleKnowledge };
  kbStatus?: TrainingStatus;
  witStatus?: TrainingStatus;
  active?: boolean;
  _createdAt?: number;
  _updatedAt?: number;
  order?: number;
  _id?: string;
}
export class Knowledge {
  knowledgeId: string;
  clientId: string;
  locales: { [key: string]: LocaleKnowledge };
  kbStatus: TrainingStatus;
  witStatus: TrainingStatus;
  active: boolean;
  _createdAt: number;
  _updatedAt: number;
  order?: number;
  _id?: string;

  constructor(param: KnowledgeParam) {
    this.knowledgeId = param.knowledgeId || "";
    this.clientId = param.clientId;
    this.locales = param.locales || {};
    this.kbStatus = param.kbStatus || TrainingStatus.Untrained;
    this.witStatus = param.witStatus || TrainingStatus.Untrained;
    this._createdAt = param._createdAt || new Date().valueOf();
    this._updatedAt = param._updatedAt || new Date().valueOf();
    this.active = param.active || false;
    if (param.order) this.order = param.order;
    if (param._id) this._id = param._id;
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export interface LocaleKnowledgeParam {
  // language?: Language;
  query?: string;
  response?: Response;
  // kbStatus?: TrainingStatus;
  // witStatus?: TrainingStatus;
}

export class LocaleKnowledge {
  // language: Language;
  query: string;
  response: Response;

  constructor(param: LocaleKnowledgeParam) {
    // this.language = param.language;
    this.query = param.query || "";
    this.response = param.response || { text: "", type: ResponseType.Text };
  }
}
