import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { SettingService } from "../../services/setting.service";

@Component({
  selector: "app-emoji-picker",
  templateUrl: "./emoji.component.html",
  styleUrls: ["./emoji.component.scss"],
})
export class EmojiButtonComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() primary: boolean;
  @Input() success: boolean;
  @Input() warn: boolean;
  @Input() error: boolean;
  @Input() black: boolean;
  @Input() icon: boolean;
  @Output() emoji = new EventEmitter();
  emojiTranslations: any;

  constructor(private settingService: SettingService) {
    if (this.settingService.translations)
      this.emojiTranslations = this.settingService.translations.emoji;
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    if (this.settingService.translations)
      this.emojiTranslations = this.settingService.translations.emoji;
    const buttons = document.getElementsByClassName("emoji-mart-anchor");
    const search = document.getElementsByClassName("emoji-mart-search-icon");
    const inputs = document.getElementsByClassName("emoji-mart-search");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener("click", (event) => {
        event.stopPropagation();
      });
    }

    for (let i = 0; i < search.length; i++) {
      search[i].addEventListener("click", (event) => {
        event.stopPropagation();
      });
    }

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("click", (event) => {
        event.stopPropagation();
      });
    }
  }

  emojiClick(event) {
    this.emoji.emit(event);
    event.$event.stopPropagation();
  }
}
