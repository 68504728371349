export const REGEX_EMOJI = /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/g;

export const REGEX_ZH = /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/g;
export const REGEX_EN = /[a-zA-Z]/g;

export const REGEX_PUNCTUATION = /[,\.\?\!，。？！"':<>`@#\$\%\^\&\*\(\)\+=/;\|~\{\}\[\]]/g;

export const REGEX_COMMA = /[,，]/g;

export const REGEX_SPACE = /\s+/g;
export const REGEX_DOUBLE_SPACE = /[ \t][ \t]+/g;

export const REGEX_EMAIL = /([a-zA-Z0-9]+)([a-zA-Z0-9_\-\.]*)([a-zA-Z0-9]+)@([a-zA-Z0-9]+)([a-zA-Z0-9_\-\.]*)\.([a-zA-Z]{2,5})/g;

export const REGEX_NUMBER = /[\+-]?[0-9]+(.[0-9]+)?/g;

export const REGEX_PARAMETER = /\{\{[^\}]*\}\}/g;

export const REGEX_URL = /(?:(?:https?|ftp):\/\/)?[a-zA-Z]+[\w\/\-?=%&:.]+\.[\w\/\-?=%&:.]+/g;

export const REGEX_PHONE = /\+?(\(?[0-9]+\)?\s?)?[0-9]{8,}/g;

export const REGEX_QUESTION_NO = /^[0-9]+\)/g;

export const REGEX_ITALIC = /_[^_]*_/g;
export const REGEX_BOLD = /\*[^\*]*\*/g;

export const REGEX_MATH_OPERATOR = /[\+\-\*\/\^\%\.0-9\s\(\)\{\}\[\]]/g;
